import React from 'react'

import InlineCta, { InlineCtaProps } from '~/components/InlineCta'
import { LineThemes } from '~/components/Line'
import SquaredCta, {
  ButtonThemes,
  SquaredCtaProps,
} from '~/components/SquaredCta'

export type CtaProps =
  | (InlineCtaProps & { ctaType: 'inline' })
  | (SquaredCtaProps & { ctaType: 'button' })

/**
 * Cta from type `rouded or inline`
 * @param props
 * @returns SquaredCta | InlineCta
 */
function Cta(props: CtaProps) {
  const { ctaType, theme, ...rest } = props ?? {}

  return ctaType === 'button' ? (
    <SquaredCta theme={theme as ButtonThemes} {...(rest as SquaredCtaProps)} />
  ) : (
    <InlineCta
      {...(rest as InlineCtaProps)}
      lineProps={{ theme: theme as LineThemes, initialLineState: 'underlined' }}
    />
  )
}

export default Cta
