import classnames from 'classnames/bind'

import Image, { ImageProps } from '~/components/Image'
import InlineCta from '~/components/InlineCta'
import { LinkProps } from '~/components/Link'
import Ratio from '~/components/Ratio'
import RichText, { RichTextBlock } from '~/components/RichText'
import WrapperWithLink from '~/components/WrapperWithLink'

import { useStyle } from '~/providers/StyleProvider'

import getAriaLabelProp from '~/utils/get-aria-label-prop'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface HeroMainProps {
  className?: string
  side: 'left' | 'right'
  title?: RichTextBlock[]
  landscapeImage?: ImageProps
  portraitImage?: ImageProps
  link?: LinkProps
  lineDecorator?: boolean
}

const DEFAULT_ALT = 'Hero main image'

function HeroMain({
  className,
  side,
  title,
  landscapeImage,
  portraitImage,
  lineDecorator,
  link,
}: HeroMainProps) {
  const titleStyle = useStyle({ textPreset: 'title-20-30' })
  const { href } = link ?? {}

  return (
    <section
      className={cx(className, css.HeroMain, {
        hasLineDecorator: lineDecorator,
      })}>
      <div className={css.wrapper}>
        <div className={css.textWrapper}>
          {title && (
            <div className={cx(css.titleWrapper, side)}>
              <RichText className={cx(titleStyle, css.title)} render={title} />
            </div>
          )}
          {link?.href && link?.children && (
            <InlineCta
              className={cx(css.link, side)}
              {...link}
              lineProps={{ theme: 'gray', initialLineState: 'underlined' }}
            />
          )}
        </div>
        {(landscapeImage || portraitImage) && (
          <div
            className={cx(css.imageWrapper, {
              hasBothImages: landscapeImage && portraitImage,
            })}>
            {landscapeImage && (
              <WrapperWithLink
                className={cx(css.landscape, css.wrapperWithLink)}
                {...getAriaLabelProp(link?.children as string)}
                href={href as string}>
                <Ratio
                  className={cx(css.ratio, { landscape: Boolean(!href) })}
                  preset="ratio-1440-860-375-500">
                  {(cn) => (
                    <Image
                      className={cn}
                      objectFit="cover"
                      layout="fill"
                      {...landscapeImage}
                      priority={true}
                      quality={80}
                      sizesFromBreakpoints={[
                        { columns: 6 },
                        { breakpoint: 'md', columns: 12 },
                      ]}
                      alt={landscapeImage?.alt ?? DEFAULT_ALT}
                    />
                  )}
                </Ratio>
              </WrapperWithLink>
            )}
            {portraitImage && (
              <WrapperWithLink
                className={cx(css.portrait, css.wrapperWithLink)}
                {...getAriaLabelProp(link?.children as string)}
                href={href as string}>
                <Ratio
                  ratio={375 / 500}
                  className={cx(css.ratio, { portrait: Boolean(!href) })}>
                  {(cn) => (
                    <Image
                      className={cn}
                      objectFit="cover"
                      layout="fill"
                      {...portraitImage}
                      sizesFromBreakpoints={[
                        { columns: 6 },
                        { breakpoint: 'md', columns: 12 },
                      ]}
                      priority={true}
                      alt={portraitImage?.alt ?? DEFAULT_ALT}
                    />
                  )}
                </Ratio>
              </WrapperWithLink>
            )}
          </div>
        )}
      </div>
    </section>
  )
}

HeroMain.defaultProps = {}

export default HeroMain
