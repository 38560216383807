import classnames from 'classnames/bind'
import React from 'react'

import ImageWithPlaceholder, {
  ImageProps,
} from '~/components/ImageWithPlaceholder'
import InlineCta from '~/components/InlineCta'
import { LinkProps } from '~/components/Link'
import Ratio from '~/components/Ratio'
import RichText, { RichTextBlock } from '~/components/RichText'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface TinyPushProps {
  className?: string
  text?: RichTextBlock[]
  link?: LinkProps
  image?: ImageProps
  reversed?: boolean
}

const DEFAULT_ALT = 'Tiny push image'

function TinyPush({ className, text, link, image, reversed }: TinyPushProps) {
  const gridStyle = useStyle({ grid: 'base-grid' })
  const textStyle = useStyle({ textPreset: 'title-fluid-16-30' })

  return (
    <section className={cx(className, css.TinyPush)}>
      <div
        className={cx(gridStyle, {
          reversed,
        })}>
        <Ratio className={css.imageContainer} ratio={18 / 25}>
          {(className) => (
            <ImageWithPlaceholder
              {...image}
              className={cx(className)}
              objectFit="cover"
              layout="fill"
              sizesFromBreakpoints={[
                { breakpoint: 'md', columns: 2 },
                { columns: 3 },
              ]}
              alt={image?.alt ?? DEFAULT_ALT}
            />
          )}
        </Ratio>
        <div className={css.line} />
        <div className={css.contentContainer}>
          {text && (
            <RichText className={cx(textStyle, css.text)} render={text} />
          )}
          {link && (
            <InlineCta
              className={css.link}
              lineProps={{ theme: 'gray', initialLineState: 'underlined' }}
              {...link}
            />
          )}
        </div>
      </div>
    </section>
  )
}

TinyPush.defaultProps = {}

export default TinyPush
