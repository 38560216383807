import classnames from 'classnames/bind'
import dynamic from 'next/dynamic'
import React from 'react'
import { useInView } from 'react-intersection-observer'

import ImageWithPlaceholder from '~/components/ImageWithPlaceholder'
import MockSwapper from '~/components/MockSwapper'
import { VideoPlayerProps } from '~/components/VideoPlayerWrapper/VideoPlayer'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const VideoPlayer = dynamic(
  () => import('~/components/VideoPlayerWrapper/VideoPlayer'),
)

const cx = classnames.bind(css)

const DEFAULT_ALT = 'Video poster image'

function VideoMock({
  poster,
  fullscreen,
}: {
  poster: VideoPlayerProps['poster']
  fullscreen: VideoPlayerProps['fullscreen']
}) {
  const gridStyle = useStyle({ grid: 'base-grid' })
  return (
    <div className={cx(gridStyle, css.VideoMock, { fullscreen })}>
      <ImageWithPlaceholder
        className={css.image}
        {...poster}
        alt={poster?.alt ?? DEFAULT_ALT}
      />
    </div>
  )
}

export default function VideoPlayerWrapper({
  poster,
  fullscreen,
  ...videoProps
}: VideoPlayerProps) {
  const { ref, inView } = useInView({
    threshold: 0.2,
  })

  return (
    <section ref={ref}>
      <MockSwapper
        RealComponent={VideoPlayer}
        MockComponent={VideoMock}
        isTriggeredInView={true}
        isTriggeredOnClick={false}
        isInView={inView}
        realProps={{ ...videoProps, fullscreen }}
        mockProps={{ poster, fullscreen }}
      />
    </section>
  )
}
