import classnames from 'classnames/bind'
import React, { useRef } from 'react'

import Cta, { CtaProps } from '~/components/Cta'
import ImageWithPlaceholder, {
  ImageProps,
} from '~/components/ImageWithPlaceholder'
import Ratio from '~/components/Ratio'
import RichText, { RichTextBlock } from '~/components/RichText'
import WrapperWithLink from '~/components/WrapperWithLink'

import { useStyle } from '~/providers/StyleProvider'

import useStickyContent from '~/hooks/useStickyContent'

import getAriaLabelProp from '~/utils/get-aria-label-prop'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface CoverPushProps {
  className?: string
  title?: RichTextBlock[]
  text?: RichTextBlock[]
  link?: CtaProps
  inversed?: boolean
  format?: 'portrait' | 'large'
  image?: ImageProps
}

const DEFAULT_ALT = 'Cover push image'

function CoverPush({
  className,
  title,
  text,
  link,
  inversed,
  image,
  format,
}: CoverPushProps) {
  const textWrapperRef = useRef<HTMLDivElement>(null)
  const imageWrapperRef = useRef<HTMLDivElement>(null)
  const { imageTop, contentTop } = useStickyContent(
    textWrapperRef,
    imageWrapperRef,
  )
  const gridStyle = useStyle({ grid: 'base-grid' })
  const titleStyle = useStyle({ textPreset: 'title-16-30' })
  const textStyle = useStyle({
    textPreset: 'text-12-16',
  })
  const coverIsLarge = format !== 'portrait'
  const ratio = coverIsLarge ? 820 / 615 : 705 / 860
  const { href } = link ?? {}

  return (
    <section
      className={cx(className, css.CoverPush, {
        inversed,
        coverIsLarge,
      })}>
      <div className={cx(gridStyle, css.wrapper)}>
        {image && (
          <WrapperWithLink
            className={css.imageWrapper}
            {...getAriaLabelProp(link?.children as string)}
            href={href as string}>
            <div
              ref={imageWrapperRef}
              className={cx({ imageWrapper: Boolean(!href) })}
              style={{ top: imageTop }}>
              <Ratio ratio={ratio}>
                {(cn) => (
                  <ImageWithPlaceholder
                    className={cn}
                    objectFit="cover"
                    layout="fill"
                    sizesFromBreakpoints={[
                      { breakpoint: 'md', columns: 6 },
                      { columns: 6 },
                    ]}
                    {...image}
                    alt={image?.alt ?? DEFAULT_ALT}
                  />
                )}
              </Ratio>
            </div>
          </WrapperWithLink>
        )}
        <div
          ref={textWrapperRef}
          className={css.content}
          style={{ top: contentTop }}>
          {title && (
            <RichText render={title} className={cx(titleStyle, css.title)} />
          )}
          {text && (
            <RichText render={text} className={cx(textStyle, css.text)} />
          )}
          {link && <Cta className={css.cta} {...link} />}
        </div>
      </div>
    </section>
  )
}

export default CoverPush
